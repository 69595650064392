import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { useState } from 'react'
import './App.css';
import Home from './pages/Home'
/* import About from './pages/About' */
/* import NotFound from './pages/NotFound'*/

const Products = lazy(() => import('./pages/Products'))
const Product = lazy(() => import('./pages/Product'))
const NotFound = lazy(() => import('./pages/NotFound'))

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
          <nav>
            <ul>
              <li>
                <NavLink to="/" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined } end>Home</NavLink>
              </li>
              <li>
                <NavLink to="/products" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined } replace>Our Products</NavLink>
              </li>
            </ul>
          </nav>
        </header>
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<Product/>} />
            <Route path="*" element={ <NotFound /> } />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
